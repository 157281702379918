/*ToDo: Turn this into styled components*/
.alert {
  position: fixed;

  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  text-align: left;
  padding: 0 15px;
  transition: all 0.3s ease-out;
  transform: translateY(100%);
}

.alert.alert--visible {
  transform: translateY(0);
}

.alert.alert--hidden {
  transform: translateY(100%);
}

.alert.alert--info {
  background: #7d8b96;
  color: #323c44;
}

.alert.alert--warning {
  background: #7d8b96;
  color: #323c44;
}

.alert.alert--success {
  background: #7ca63c;
  color: #fff;
}

.alert.alert--error {
  background: #ff495c;
  color: #fff;
}

.alert .alert__header {
  font-size: 20px;
  font-weight: bold;
}

.alert .alert__body {
  margin: 12px 0 15px;
  padding: 10px;
  font-size: 16px;
}
